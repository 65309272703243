@import '~rs-emd-ui-atoms/dist/styles/design-tokens/variables.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/breakpoints.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/flex.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/typography.scss';

.footer-container {
  margin-top: 5.6rem;

  .section-heading {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .top-sections {
    background: $brand-shadow;
    color: $white;

    .top-sections-inner {
      box-sizing: border-box;
    }
    .payments {
      .methods {
        @include flex(row);
        flex-wrap: wrap;
        margin-top: $s-sm;

        > * {
          border-radius: 0.4rem;
          margin-bottom: $s-sm;
          margin-right: $s-sm !important;
          background-color: $white;
          padding: $s-sm;
          height: 2.4rem;
          width: auto;
        }
      }
    }
    .settings {
      .options {
        .dropdown {
          color: $primaryGrey;

          label {
            margin-top: $s-sm;
            color: $white;
          }

          &.countries,
          &.languages {
            width: 30.4rem;
            .country-opt {
              @include flex(row, $gap: $s-sm);
              @include flex-align(start, center);
            }
          }

          &.currencies {
            min-width: 12.5rem;
          }
        }

        .other-opts-container {
          @include flex(row, $gap: $s-md);
          .vat {
            margin-top: $s-xxl;
            align-self: flex-end;
            @include flex(row, $gap: $s-xs);
            @include flex-align(start, end);
          }
        }
      }
    }
  }

  .contact-us {
    background-color: $mono-800;
    color: $white;

    .contact-us-inner {
      @include flex(column);
    }

    .select-country {
      margin-top: $s-sm;
      background-color: $white;
      color: $primaryGrey;
      max-width: 28.8rem;
      min-width: 20rem;
      border-radius: 0.4rem;
      padding: $s-md;
      align-self: center;

      p {
        margin: 0;
      }

      .icons {
        color: $secondaryPurple;
        text-align: center;
        margin-bottom: $s-md;

        svg:not(:last-child) {
          margin-right: $s-sm;
        }
      }
    }
  }
  .section {
    box-sizing: border-box;

    &.contact-us-inner {
      .details {
        > div {
          margin-top: $s-sm;
        }

        .contact-label p {
          font-weight: bold;
          margin: 0;
        }

        .phone-button {
          display: none;
        }

        .link {
          @include flex(row, $gap: $s-sm);
          @include flex-align(start, center);
          color: $white;
          margin-top: $s-sm;
          line-height: $f-sm;

          i {
            padding-right: $s-sm;
          }
          &.email-link {
            text-decoration: underline;
          }
        }
      }

      .socials {
        .social-networks {
          @include flex(row);
          flex-wrap: wrap;
          margin: $s-xs 0-$s-md 0-$s-md 0;

          > * {
            margin-bottom: $s-md;
          }
        }
      }
    }

    &.helpful-links {
      background-color: $mono-800;

      > * {
        color: $white;
      }

      .column-links {
        @include flex(row, $gap: $s-xxl);
        border-bottom: 0.2rem $primaryRed solid;

        .column {
          @include flex(column, $gap: $s-sm);

          .column-heading {
            font-weight: bold;
            padding-top: 1.3rem;
          }
        }
      }

      .bottom-links {
        a:not(:last-of-type)::after {
          content: '';
          display: inline-block;
          width: $s-xs;
          height: $s-xs;
          border-radius: 0.2rem;
          background-color: $primaryRed;
          margin: 0.2rem $s-sm;
        }
      }

      a {
        color: $white;
      }
    }

    &.copyright {
      @include flex(column);
      @include flex-align(start, stretch);
      background-color: $primaryRed;
      color: $white;

      .copyright-heading {
        @include label();
        font-weight: bold;
      }

      address {
        flex-grow: 1;
        padding: $s-sm 0 $s-md 0;
        font-style: normal;
      }

      .developed-by {
        border-top: 0.2rem $white solid;
        padding-top: $s-md;
      }
    }
  }

  &.footer-container-basic,
  &.footer-container-minimal {
    .top-sections {
      display: none;
    }

    .helpful-links {
      display: none;
    }

    .copyright {
      max-width: initial !important;
    }
  }

  // mobile
  @include media-breakpoint-down(sm) {
    .top-sections-inner {
      padding: 0 $s-sm;
      > .section {
        padding: $s-md 0;
      }
    }

    .bottom-sections {
      @include flex(column);

      .helpful-links {
        .column-links {
          padding-bottom: $s-md;
        }
        .bottom-links {
          padding: $s-md 0 0 0;
        }
      }
    }
    .section {
      padding: $s-md $s-sm;

      &.settings .options {
        .country-container,
        .lang-container {
          flex-grow: 1;
          margin-bottom: $s-md;

          .countries,
          .languages {
            width: 100%;
          }
        }

        .other-opts-container {
          flex-grow: 1;
          .currencies {
            width: 50%;
          }
        }
      }

      &.contact-us-inner .details {
        @include flex(column);

        .contact-label {
          @include flex(row);
          p {
            margin-right: $s-xs;
          }
        }

        .phone-button {
          display: block;
          padding: $s-xs 0 $s-lg 0;
        }

        .phone-link {
          display: none;
        }

        .socials {
          margin-top: $s-lg;
          .social-networks {
            margin-right: 0;
          }
        }
      }

      &.copyright {
        address {
          font-size: $f-xsm;
          line-height: 1.8rem;
        }
      }
    }
  }

  // tablet & mobile
  @include media-breakpoint-down(md) {
    .section.settings.after-payment {
      border-top: 0.2rem $primaryRed solid;
    }
  }

  // tablet
  @include media-breakpoint-only(md) {
    .top-sections-inner {
      padding: 0 $s-lg;
      > .section {
        padding: $s-md 0;
      }
    }

    .section {
      &.payments {
        .options {
          @include flex(row, $gap: $s-lg);
          @include flex-align(flex-start, flex-end);

          .bank-transfer {
            padding-bottom: $s-sm;
          }
        }
      }
      &.contact-us-inner {
        .socials {
          flex-basis: 12rem;
          flex-shrink: 1;
          flex-grow: 1;
        }
      }
    }
  }

  //tablet & above
  @include media-breakpoint-up(md) {
    .section {
      padding: $s-lg;
      min-width: 26rem;

      &.contact-us-inner {
        .details {
          @include flex(row, $gap: $s-xl);
          .phone {
            min-width: 21.5rem;
          }

          .email {
            min-width: 29.6rem;
          }
        }
      }

      &.payments {
        margin-right: $s-md;
      }
      &.settings .options {
        @include flex(row, $gap: $s-lg);
      }
    }

    .bottom-sections {
      @include flex(row);

      .padding {
        width: calc((100vw - #{$page-width}) / 2);
        &.left {
          background-color: $mono-800;
        }
        &.right {
          background-color: $primaryRed;
        }
      }

      .helpful-links {
        min-width: calc((min(100vw, #{$page-width}) / 100) * 60);
        max-width: 93.9rem;
        flex: 1 1 93.9rem;

        .column-links {
          padding-bottom: $s-lg;
        }

        .bottom-links {
          padding-top: $s-lg;
        }
      }
      .copyright {
        min-width: calc((min(100vw, #{$page-width}) / 100) * 38);
        max-width: 50.1rem;
        flex: 1 1 50.1rem;
        box-sizing: border-box;

        address {
          padding-bottom: $s-lg;
          line-height: 2.4rem;
        }
      }
    }

    &.footer-container-basic {
      .bottom-sections {
        .padding {
          background-color: $primaryRed;
        }
      }
    }
  }

  // desktop and above
  @include media-breakpoint-up(lg) {
    .top-sections-inner {
      padding: $s-lg;
      @include flex(row);

      > .section {
        padding: 0 $s-md 0 0;
      }
    }

    .section {
      padding: $s-lg;

      &.payments {
        min-width: 32.6rem;
      }
      &.settings.after-payment {
        border-left: 0.2rem $primaryRed solid;
        padding-left: $s-md;
      }
    }
  }
}
