@import '~rs-emd-ui-atoms/dist/styles/design-tokens/variables.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/breakpoints.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/flex.scss';
@import '~rs-emd-ui-atoms/dist/styles/mixins/typography.scss';

.parts-list-details-container {
  @include flex(row);
  background-color: $white;

  .loader-container {
    position: relative;
    width: 100%;
  }

  .parts-list-details {
    @include flex(column, $gap: $s-md);
    width: 100%;

    .footer-btns {
      border-top: 0.1rem solid $mono-200;
      padding-top: $s-md;

      .back-btn {
        @include flex(row, $gap: $s-xs);
        @include flex-align(start, center);
        width: fit-content;

        span {
          text-decoration: underline;
        }
      }

      .add-list-to-basket-btn {
        width: fit-content;
      }
    }
  }

  .invalid-products {
    @include flex(column, $gap: $s-md);
    line-height: 2rem;
    color: $error;
  }

  @include gte-tablet {
    .parts-list-details {
      padding: $s-md $s-lg;

      .footer-btns {
        @include flex(row, $gap: $s-md);
        @include flex-align(space-between);
      }
    }
  }

  @include lte-desktop {
    margin: 0 $s-md;
  }

  @include mobile {
    margin: 0;

    .parts-list-details {
      padding: $s-sm $s-md;

      .footer-btns {
        @include flex(column, $gap: $s-md);
      }
    }
  }
}
